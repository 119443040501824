<template>
    <div class="timeline-header">
        <at-input
            v-model="autocompleteInput"
            class="timeline-input"
            :placeholder="question"
            @focus="onInputFocus"
            @input="filterAutocompleteSuggestions"
        ></at-input>
        <transition name="slide-up">
            <div v-show="dropdownVisible" class="autocomplete-dropdown" @click.stop>
                <h6>Previously tracked time entries</h6>
                <div>
                    <div class="autocomplete-dropdown-container">
                        <ul>
                            <li v-for="subItem in autocompleteSuggestions" :key="subItem.id">
                                <at-tooltip placement="right" :content="formatTooltipContent(subItem)">
                                    <div @click="onSelectItem(subItem)">
                                        {{ subItem.description }} - {{ subItem.project }} - {{ subItem.task }} -
                                        {{ subItem.time }}
                                    </div>
                                </at-tooltip>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </transition>
        <AdditionalElements
            :tasks="tasks"
            :selectedTaskID="selectedTaskID"
            :selectedTagIDs="selectedTagIDs"
            :selectedBillable="selectedBillable"
            @task-selected="handleSelectedTask"
            @tag-selected="handleSelectedTags"
            @billable-selected="handleBillable"
        />
        <div class="timeline-interface">
            <Timer :time="time" :isRunning="isTimerRunning" />
            <at-button
                class="timer-button"
                :class="{ active: isTimerRunning, inactive: !isTimerRunning }"
                @click="toggleTimer"
            >
                <span class="timer-button-stop"
                    ><i class="icon icon-stop-circle"></i>{{ $t('dashboard.buttons.stop') }}</span
                >
                <span class="timer-button-start"
                    ><i class="icon icon-play"></i>{{ $t('dashboard.buttons.start') }}</span
                >
            </at-button>
        </div>
    </div>
</template>

<script>
    import Timer from './Timer.vue';
    import TimerService from '../services/timer.service';
    import AdditionalElements from './AdditionalElements.vue';
    import debounce from 'lodash/debounce';
    import TimeIntervalsService from '@/services/resource/time-interval.service';
    import { mapGetters, mapActions } from 'vuex';
    import ApiService from '@/services/api';

    let reconnectInterval = 5000;
    let maxRetries = 10;
    let retries = 0;

    export default {
        name: 'ConnectionTimerInterface',
        props: {
            tasks: {
                type: Array,
                default: () => [],
            },
            itemData: {
                type: Object,
                default: () => ({}),
            },
            timelineItems: {
                type: Array,
                default: () => [],
            },
            timeIntervalStart: {
                type: Object,
                default: undefined,
            },
            copyDescription: {
                type: String,
                default: '',
            },
        },
        components: {
            AdditionalElements,
            Timer,
        },
        data() {
            return {
                question: this.$t('dashboard.question'),
                selectedTaskID: null,
                selectedTagIDs: null,
                selectedTimeInterval: null,
                selectedBillable: false,
                timerService: new TimerService(),
                autocompleteInput: '',
                autocompleteSuggestions: [],
                dropdownVisible: false,
                isTimerRunning: false,
                time: 0,
                elapsedSeconds: 0,
                startTime: null,
                timeIntervalsService: new TimeIntervalsService(),
                redisIntervalID: null,
                unsyncedIntervalStartData: 'unsyncedStartIntervalData',
                wasOffline: false,

                wsUrl: process.env.VUE_APP_WS_URL || '',
                ws: null,
            };
        },
        mounted() {
            document.addEventListener('click', this.handleOutsideClick);
            window.addEventListener('keydown', this.handleKeyDown);
            this.retryInterval = setInterval(this.retrySync, 10000);
            this.resumeTimerFromStorage();
            this.timerActiveRequest();
            this.connectWebSocket();
        },
        beforeDestroy() {
            document.removeEventListener('click', this.handleOutsideClick);
            window.removeEventListener('keydown', this.handleKeyDown);
        },
        computed: {
            ...mapGetters('user', ['user']),
            ...mapGetters('company', ['company']),
            ...mapGetters('websocket', ['timelinesChannel', 'timerChannel']),
            ...mapGetters('intervals', ['getIntervalsByCompany']),
            ...mapGetters('tag', ['tags']),
            tagIDs() {
                if (this.selectedTagIDs) {
                    return this.selectedTagIDs.map(tag => {
                        return tag.attributes.id;
                    });
                }
                return [];
            },
        },
        methods: {
            ...mapActions('intervals', ['saveInterval', 'removeLocalInterval']),
            handleKeyDown(event) {
                if (event.key === 'Enter') {
                    this.toggleTimer();
                }
            },
            handleSelectedTask(task) {
                this.selectedTaskID = task.attributes.id;
                if (this.startTime) {
                    this.updateTimeInterval({ task_id: this.selectedTaskID }, this.redisIntervalID);
                }
            },
            handleSelectedTags(tags) {
                this.selectedTagIDs = tags;
                if (this.startTime) {
                    this.updateTimeInterval({ tag_ids: this.tagIDs }, this.redisIntervalID);
                }
            },
            handleBillable(value) {
                this.selectedBillable = value;
                if (this.startTime) {
                    this.updateTimeInterval({ billable: this.selectedBillable }, this.redisIntervalID);
                }
            },
            formatTooltipContent(subItem) {
                return `${subItem.text} - ${subItem.project} - ${subItem.task} - ${subItem.time}`;
            },
            onInputFocus() {
                if (this.timelineItems.length > 0) {
                    const items = this.timelineItems[0].items.slice(0, 6);
                    this.autocompleteSuggestions = items;
                    this.dropdownVisible = true;
                }
            },
            onSelectItem(subItem) {
                this.autocompleteInput = subItem.description;
                this.selectedTaskID = subItem.task_id;
                this.selectedTagIDs = subItem.tags;
                this.selectedBillable = subItem.billable;
                this.dropdownVisible = false;
                this.startTimer();
            },
            handleOutsideClick(event) {
                if (!this.$el.contains(event.target)) {
                    this.dropdownVisible = false;
                }
            },

            filterAutocompleteSuggestions: debounce(async function (newDescription) {
                if (this.startTime) {
                    this.updateTimeInterval({ description: newDescription }, this.redisIntervalID);
                }
            }, 1000),

            updateTimeInterval(params, id) {
                try {
                    if (!this.timelinesChannel.isConnected || !id) {
                        throw new Error('WebSocket not connected, action Update');
                    }

                    this.timeIntervalsService.update(params, id);
                } catch (error) {
                    console.warn('Error update Interval Params');
                }

                const savedState = JSON.parse(localStorage.getItem('timerState'));
                const updatedStateParams = { ...savedState, ...params };

                this.saveStartDateToLocalStorage(updatedStateParams);
                this.saveTimerStateToLocal(updatedStateParams);
            },

            runFakeTimer() {
                if (!this.interval) {
                    this.interval = setInterval(() => {
                        const now = Date.now();
                        this.time = Math.floor((now - this.startTime) / 1000);
                    }, 1000);
                }
            },

            stopFakeTimer() {
                if (this.interval) {
                    clearInterval(this.interval);
                    this.interval = null;
                    this.startTime = null;
                    this.time = 0;
                    this.elapsedSeconds = 0;
                }
            },

            async startTimer() {
                const startTime = this.startTime || Date.now();
                const timerData = {
                    task_id: this.selectedTaskID,
                    description: this.autocompleteInput,
                    tag_ids: this.selectedTagIDs || [],
                    billable: this.selectedBillable,
                    startAt: startTime,
                };

                try {
                    const params = this.prepareStartDataToRequest(timerData);
                    const { data } = await this.timerService.start(params);
                    this.redisIntervalID = data.id;
                    timerData.redisIntervalID = this.redisIntervalID;
                } catch (error) {
                    console.warn('Backend unavailable. Saving Start interval data locally.');
                    this.saveStartDateToLocalStorage(timerData);
                }

                this.startTime = startTime;
                this.runFakeTimer();
                this.isTimerRunning = true;
                this.saveTimerStateToLocal(timerData);
            },

            async saveToLocalStorage(timerData) {
                await this.saveInterval({ companyId: this.company.id, interval: timerData });
                this.$emit('load-local-intervals');
            },

            saveStartDateToLocalStorage(timerData) {
                const unsyncedIntervalStartData =
                    JSON.parse(localStorage.getItem(this.unsyncedIntervalStartData)) || '';
                localStorage.setItem(this.unsyncedIntervalStartData, JSON.stringify(timerData));
            },

            async retrySync() {
                const unsyncedIntervals = this.getIntervalsByCompany(this.company.id);
                const unsyncedIntervalStartData = JSON.parse(localStorage.getItem(this.unsyncedIntervalStartData));

                if (!this.timelinesChannel.isConnected || !this.timerChannel.isConnected) {
                    this.handleReconectWS();
                    return;
                }

                if (unsyncedIntervals.length > 0) {
                    unsyncedIntervals.forEach(async interval => {
                        try {
                            const params = this.prepareCreateDataToRequest(interval);
                            await this.timeIntervalsService.save(params);
                            await this.removeLocalInterval({ companyId: this.company.id, uuid: interval.uuid });
                            this.$emit('remove-local-interval', interval.uuid);
                        } catch (error) {
                            console.error(`Failed to sync interval at index ${interval.uuid}`, error);
                        }
                    });
                    this.$emit('load-local-intervals');
                }

                if (unsyncedIntervalStartData) {
                    try {
                        const params = this.prepareStartDataToRequest(unsyncedIntervalStartData);
                        const { data } = await this.timerService.start(params);
                        this.redisIntervalID = data.id;
                        localStorage.setItem(
                            'timerState',
                            JSON.stringify({
                                ...unsyncedIntervalStartData,
                                redisIntervalID: data.id,
                            }),
                        );

                        localStorage.removeItem(this.unsyncedIntervalStartData);
                    } catch (error) {
                        console.warn('Error Start request to Backend!');
                    }
                }
            },

            prepareCreateDataToRequest(interval) {
                return {
                    time_interval: {
                        task_id: interval.task_id,
                        user_id: this.user.id,
                        start_at: new Date(interval.startAt).toISOString(),
                        end_at: new Date(interval.endAt).toISOString(),
                        description: interval.description,
                        billable: interval.billable,
                    },
                    tag_ids: interval.tag_ids.map(obj => obj.attributes.id),
                };
            },

            prepareStartDataToRequest(interval) {
                return {
                    time_interval: {
                        task_id: interval.task_id,
                        start_at: new Date(interval.startAt).toISOString(),
                        description: interval.description,
                        billable: interval.billable,
                    },
                    tag_ids: interval.tag_ids.map(obj => obj.attributes.id),
                };
            },

            saveTimerStateToLocal(timerData) {
                localStorage.setItem(
                    'timerState',
                    JSON.stringify({
                        ...timerData,
                        isRunning: true,
                    }),
                );
            },

            async stopTimer() {
                try {
                    if (!this.timelinesChannel.isConnected) {
                        throw new Error('WebSocket not connected');
                    }

                    await this.timerService.stop();
                } catch (error) {
                    console.warn('Backend unavailable or WebSocket disconnected. Saving Interval data locally.');

                    const savedState = JSON.parse(localStorage.getItem('timerState')) || {};
                    savedState.endAt = Date.now();
                    this.saveToLocalStorage(savedState);
                }

                localStorage.removeItem(this.unsyncedIntervalStartData);
                localStorage.removeItem('timerState');
                this.redisIntervalID = null;
                this.isTimerRunning = false;

                this.stopFakeTimer();
            },

            resumeTimerFromStorage() {
                const savedState = JSON.parse(localStorage.getItem('timerState'));

                if (savedState && savedState.isRunning) {
                    const now = Date.now();
                    const elapsedTime = Math.floor((now - savedState.startAt) / 1000);

                    this.startTime = savedState.startAt;
                    this.selectedTaskID = savedState.task_id;
                    this.selectedBillable = savedState.billable;
                    this.selectedTagIDs = savedState.tag_ids;
                    this.autocompleteInput = savedState.description;
                    this.time = elapsedTime;

                    this.redisIntervalID = savedState.redisIntervalID || null;

                    this.runFakeTimer();
                    this.isTimerRunning = true;
                }
            },

            toggleTimer() {
                if (this.isTimerRunning) {
                    this.stopTimer();
                } else {
                    this.startTimer();
                }
            },

            async timerActiveRequest() {
                try {
                    if (!this.isTimerRunning) {
                        const res = await this.timerService.timersActive();
                        if (res.status === 200) {
                            const params = { data: res.data };
                            this.wsStartTimer(params);
                        }
                    }
                } catch (error) {
                    console.warn('Error Sync request to Backend!');
                }
            },

            handleOffline: debounce(function () {
                if (!this.wasOffline) {
                    this.wasOffline = true;
                    this.$Notify({
                        type: 'warning',
                        title: this.$t('internet.offline_title'),
                        message: this.$t('internet.offline_message', { time: new Date().toLocaleTimeString() }),
                    });
                }
            }, 300),

            handleOnline: debounce(function () {
                if (this.wasOffline) {
                    this.wasOffline = false;
                    this.$Notify({
                        type: 'success',
                        title: this.$t('internet.online_title'),
                        message: this.$t('internet.online_message', { time: new Date().toLocaleTimeString() }),
                    });
                }
            }, 300),

            connectWebSocket() {
                if (!this.wsUrl || !this.company) return;

                if (this.ws && this.ws.readyState !== WebSocket.CLOSED) {
                    console.warn('WebSocket connection already exists');
                    return;
                }

                this.ws = new WebSocket(this.wsUrl);

                this.ws.onopen = () => {
                    retries = 0;

                    const subTimer = {
                        command: 'subscribe',
                        identifier: JSON.stringify({
                            channel: 'TimerChannel',
                            company_id: this.company.id,
                        }),
                    };

                    this.ws.send(JSON.stringify(subTimer));
                    this.$store.dispatch('websocket/updateTimerChannelStatus', true);
                    this.handleOnline();
                };

                this.ws.onmessage = event => {
                    const data = JSON.parse(event.data);
                    if (data.type !== 'ping' && data.message) {
                        const { action, timer } = data.message;

                        const actions = {
                            start_timer: () => this.wsStartTimer(timer),
                            update_timer: () => this.wsUpdateTimer(timer),
                            stop_timer: () => this.wsStopTimer(),
                        };

                        if (actions[action]) {
                            actions[action]();
                        } else {
                            console.warn(`Unhandled action: ${action}`);
                        }
                    }
                };

                this.ws.onclose = () => {
                    if (process.env.NODE_ENV === 'development') {
                        console.warn('WebSocket connection closed');
                    }
                    this.$store.dispatch('websocket/updateTimerChannelStatus', false);
                    this.handleOffline();
                    this.retryWebSocketConnection();
                };

                this.ws.onerror = error => {
                    this.$store.dispatch('websocket/updateTimerChannelStatus', false);
                    this.retryWebSocketConnection();
                };
            },
            retryWebSocketConnection() {
                if (retries < maxRetries) {
                    retries += 1;
                    setTimeout(() => {
                        this.connectWebSocket();
                    }, reconnectInterval);
                } else {
                    if (process.env.NODE_ENV === 'development') {
                        console.warn('The maximum number of reconnections has been reached. TimerChannel');
                    }
                }
            },

            async handleReconectWS() {
                const apiService = new ApiService(this.$store);
                try {
                    const status = await apiService.status();
                    if (status) {
                        if (!this.timerChannel.isConnected) {
                            retries = 0;
                            this.retryWebSocketConnection();
                        }

                        if (!this.timelinesChannel.isConnected) {
                            this.$emit('reconect-ws', status);
                        }
                    }
                } catch (error) {
                    console.warn('Backend unavailable.');
                }
            },

            wsStartTimer(timer) {
                if (!this.isTimerRunning) {
                    const startTime = new Date(timer.data.data.start_at).getTime();

                    this.redisIntervalID = timer.data.id;
                    this.selectedTaskID = timer.data.data.task_id;
                    this.autocompleteInput = timer.data.data.description;
                    this.selectedBillable = timer.data.data.billable;

                    this.selectedTagIDs = (timer.data.data.tag_ids ?? []).length
                        ? this.tags.interval.filter(tag => timer.data.data.tag_ids.includes(tag.attributes.id))
                        : [];

                    const timerData = {
                        task_id: this.selectedTaskID,
                        description: this.autocompleteInput,
                        tag_ids: this.selectedTagIDs || [],
                        billable: this.selectedBillable,
                        startAt: startTime,
                    };

                    this.startTime = startTime;
                    this.runFakeTimer();
                    this.isTimerRunning = true;

                    this.saveTimerStateToLocal(timerData);
                }
            },

            wsUpdateTimer(timer) {
                if (this.isTimerRunning) {
                    this.redisIntervalID = timer.data.id;
                    this.selectedTaskID = timer.data.data.task_id;
                    this.autocompleteInput = timer.data.data.description;
                    this.selectedBillable = timer.data.data.billable;

                    this.selectedTagIDs = (timer.data.data.tag_ids ?? []).length
                        ? this.tags.interval.filter(tag => timer.data.data.tag_ids.includes(tag.attributes.id))
                        : [];
                }
            },

            wsStopTimer() {
                if (this.isTimerRunning) {
                    localStorage.removeItem(this.unsyncedIntervalStartData);
                    localStorage.removeItem('timerState');
                    this.redisIntervalID = null;
                    this.isTimerRunning = false;

                    this.stopFakeTimer();
                }
            },
        },
        watch: {
            autocompleteInput(newValue) {
                if (newValue.length >= 1) {
                    this.filterAutocompleteSuggestions(newValue);
                } else {
                    this.autocompleteSuggestions = [];
                    this.dropdownVisible = false;
                }

                const savedState = JSON.parse(localStorage.getItem('timerState')) || {};
                localStorage.setItem(
                    'timerState',
                    JSON.stringify({
                        ...savedState,
                        description: newValue,
                    }),
                );
            },

            timeIntervalStart(params) {
                this.autocompleteInput = params.description;
                this.selectedTaskID = params.task_id;
                this.selectedTagIDs = params.tags;
                this.selectedBillable = params.billable;
                this.startTimer();
            },
            copyDescription(description) {
                this.autocompleteInput = description;
            },
        },
    };
</script>
<style lang="scss" scoped>
    .autocomplete-dropdown {
        position: absolute;
        width: 100%;
        height: auto;
        max-height: 360px;
        max-width: 430px;
        overflow: visible;
        z-index: 100;
        background-color: $background-color-light;
        border: 1px solid rgb(235, 231, 235);
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        padding: 15px;
        padding-bottom: 10px;
        top: calc(100% - 5px);
        ul {
            max-width: 400px;
            list-style: none;
            overflow: visible;
            margin: 0;
            padding: 0;
        }
        li {
            padding: 15px;
            padding-top: 5px;
            padding-bottom: 5px;
            cursor: pointer;
            &:hover {
                background-color: #f0f0f0;
            }
        }
    }
    @media (max-width: 600px) {
        .autocomplete-dropdown {
            max-width: 360px;
        }
        .timeline .autocomplete-dropdown-container .at-tooltip__trigger > div {
            max-width: 320px;
        }
    }
</style>
