<template>
    <div class="timeline">
        <div class="timeline-times">
            <div v-if="tasks && tags" class="timeline-menu">
                <ConnectionTimerInterface
                    :tasks="tasks"
                    :itemData="selectedItem"
                    :timelineItems="timelineItems"
                    :timeIntervalStart="timeIntervalParams"
                    :copyDescription="onCopyDescription"
                    @reconect-ws="handleReconectWS"
                    @load-local-intervals="handleAddLocalIntervals"
                    @remove-local-interval="handleRemoveLocalInterval"
                />
            </div>
            <div v-if="tasks && tags" class="timeline-items-list">
                <h2>{{ $t('dashboard.list') }}</h2>
                <TimelineItem
                    v-for="(item, index) in timelineItems"
                    :key="index"
                    :item="item"
                    :tasks="tasks"
                    @load-data="handleDataLoad"
                    @start-task-timer="startTaskTimer"
                    @copy-description="handleDescription"
                    @remove-local-interval="handleRemoveLocalInterval"
                />
            </div>
        </div>

        <div v-if="isDataLoading" class="loading-spinner">
            <div class="spinner"></div>
        </div>
        <portal-target name="modals"></portal-target>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';
    import { mapGetters, mapMutations } from 'vuex';
    import { getDateToday } from '@/utils/time';
    import TimelineItem from '../../components/TimelineItem.vue';
    import ConnectionTimerInterface from '../../components/ConnectionTimerInterface.vue';
    import TagService from '../../../tags/services/tag.service';
    import TaskService from '@/services/resource/task.service.js';

    let reconnectInterval = 5000;
    let maxRetries = 10;
    let retries = 0;

    export default {
        name: 'ShowTimes',
        components: {
            ConnectionTimerInterface,
            TimelineItem,
        },
        data() {
            return {
                tagService: new TagService(),
                taskService: new TaskService(),
                type: 'day',
                activeTask: +localStorage.getItem('timeline.active-task') || 0,
                isDataLoading: false,
                timelines: null,
                timelineItems: [],
                selectedItem: null,
                timeIntervalParams: null,
                onCopyDescription: null,
                page: 1,
                tasks: null,
                tags: null,
                elapsedTime: 0,
                taskId: null,

                wsUrl: process.env.VUE_APP_WS_URL || '',
                ws: null,
            };
        },
        async created() {
            await this.loadData();
            await this.loadTasksData();
            await this.loadTagsData();
            window.addEventListener('scroll', this.handleScroll);
            this.connectWebSocket();
        },
        computed: {
            ...mapGetters('dashboard', ['service']),
            ...mapGetters('company', ['company']),
            ...mapGetters('user', ['user']),
            ...mapGetters('websocket', ['timelinesChannel']),
            ...mapGetters('intervals', ['getIntervalsByCompany']),
            transformedData() {
                return this.transformData(this.timelines);
            },
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.handleScroll);
        },
        methods: {
            getDateToday,
            ...mapMutations({
                setTimezone: 'dashboard/setTimezone',
            }),
            async handleScroll() {
                const bottomOfWindow = window.scrollY + window.innerHeight >= document.documentElement.scrollHeight;
                if (bottomOfWindow) {
                    this.page += 1;
                    this.additionalDataLoad();
                }
            },
            handleDescription(description) {
                this.onCopyDescription = description;
            },

            handleAddLocalIntervals() {
                const localIntervals = this.transformTasksFromLocalStorage();
                this.timelines = [...this.timelines, ...localIntervals];

                const uniqueIntervals = this.timelines.filter(
                    (interval, index, self) => index === self.findIndex(t => t.id === interval.id),
                );

                this.timelines = uniqueIntervals;
                this.timelineItems = this.transformedData;
            },

            handleRemoveLocalInterval(uuid) {
                this.timelines = this.timelines.filter(interval => interval.id != uuid);
                this.timelineItems = this.transformedData;
            },

            async handleDataLoad() {
                this.page = 1;
                await this.loadData();
            },

            setActive(section) {
                this.activeSection = section;
            },
            selectItem(item) {
                this.selectedItem = item;
            },
            loadData: debounce(async function (withLoadingIndicator = true) {
                this.isDataLoading = withLoadingIndicator;

                const { data } = await this.service.loadTimes({ page: this.page });

                if (!data) {
                    return;
                }

                this.timelines = data.data;
                this.timelines = [...this.timelines, ...this.transformTasksFromLocalStorage()];
                this.timelineItems = this.transformedData;

                this.isDataLoading = false;
            }, 350),

            async additionalDataLoad(withLoadingIndicator = true) {
                this.isDataLoading = withLoadingIndicator;

                try {
                    const { data } = await this.service.loadTimes({ page: this.page });

                    if (!data) {
                        this.isDataLoading = false;
                        return;
                    }

                    const newItems = this.transformData(data.data);

                    newItems.forEach(newItem => {
                        const existingItem = this.timelineItems.find(item => item.date === newItem.date);
                        if (existingItem) {
                            existingItem.items.push(...newItem.items);
                        } else {
                            this.timelineItems.push(newItem);
                        }
                    });

                    this.timelineItems.forEach(item => {
                        item.items.sort((a, b) => new Date(b.start_at) - new Date(a.start_at));
                    });
                } catch (error) {
                    console.error('Error loading data:', error);
                } finally {
                    this.isDataLoading = false;
                }
            },

            startTaskTimer(timeIntervalParams) {
                this.timeIntervalParams = timeIntervalParams;
            },

            formatDuration(seconds) {
                const hrs = Math.floor(seconds / 3600);
                const mins = Math.floor((seconds % 3600) / 60);
                const secs = seconds % 60;
                return `${hrs}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
            },
            transformData(timelines) {
                const groupedByDate = timelines
                    .sort((a, b) => new Date(b.attributes.start_at) - new Date(a.attributes.start_at))
                    .reduce((acc, curr) => {
                        const date = new Date(curr.attributes.start_at).toDateString();
                        if (!acc[date]) acc[date] = [];
                        acc[date].push(curr);
                        return acc;
                    }, {});

                return Object.keys(groupedByDate)
                    .sort((a, b) => new Date(b) - new Date(a))
                    .map(date => ({
                        date,
                        items: groupedByDate[date].map(item => ({
                            description: item.attributes.description || 'No description',
                            project: item.attributes.task?.attributes?.project?.attributes?.name || null,
                            task: item.attributes.task?.attributes?.task_name || null,
                            duration: this.formatDuration(item.attributes.duration),
                            task_id: item.attributes.task?.attributes?.id || null,
                            id: item.attributes.id,
                            created_at: new Date(item.attributes.created_at),
                            tags: item.attributes.tags,
                            billable: item.attributes.billable,
                            start_at: item.attributes.start_at,
                            end_at: item.attributes.end_at,
                            temp: item.attributes.temp || false,
                        })),
                    }));
            },

            loadTasksData: debounce(async function (withLoadingIndicator = true) {
                this.isDataLoading = withLoadingIndicator;
                try {
                    const { data } = await this.taskService.getWithFilters({ disable_pagy: true });
                    this.tasks = data.data;
                } catch (error) {
                    if (process.env.NODE_ENV === 'development') {
                        console.error('Error loading tasks:', error);
                    }
                }

                this.isDataLoading = false;
            }, 350),

            async loadTagsData() {
                try {
                    const { data } = await this.tagService.getWithFilters({ disable_pagy: true, type: 'interval' });
                    this.$store.dispatch('tag/setIntervalTagsData', data.data);
                    this.tags = data.data;
                } catch (error) {
                    if (process.env.NODE_ENV === 'development') {
                        console.log('Error tags request', error);
                    }
                }
            },

            transformTasksFromLocalStorage() {
                const storedData = this.getIntervalsByCompany(this.company.id);

                if (!storedData) {
                    return [];
                }

                return storedData.map(interval => {
                    const startAtDate = new Date(interval.startAt);
                    const endAtDate = new Date(interval.endAt);
                    const createdAt = new Date();

                    return {
                        id: interval.uuid,
                        type: 'timeline',
                        attributes: {
                            id: interval.uuid,
                            task_id: interval.task_id,
                            user_id: this.user.id,
                            start_at: startAtDate.toISOString(),
                            end_at: endAtDate.toISOString(),
                            handwrought: false,
                            temp: true,
                            created_at: createdAt.toISOString(),
                            updated_at: createdAt.toISOString(),
                            description: interval.description,
                            billable: interval.billable,
                            task: {
                                id: interval.task_id,
                                attributes: {
                                    id: interval.task_id,
                                },
                            },
                            tags: interval.tag_ids,
                            duration: Math.floor((interval.endAt - interval.startAt) / 1000),
                        },
                    };
                });
            },

            connectWebSocket() {
                if (!this.wsUrl) return;
                if (!this.company) return;

                this.ws = new WebSocket(this.wsUrl);

                this.ws.onopen = () => {
                    retries = 0;

                    const subTimer = {
                        command: 'subscribe',
                        identifier: JSON.stringify({
                            channel: 'TimelineChannel',
                            company_id: this.company.id,
                        }),
                    };

                    this.ws.send(JSON.stringify(subTimer));
                    this.$store.dispatch('websocket/updateTimelinesChannelStatus', true);
                };

                this.ws.onmessage = event => {
                    const data = JSON.parse(event.data);
                    if (data.type !== 'ping' && data.message) {
                        const { action, timeline } = data.message;

                        const actions = {
                            new_timeline: () => this.wsAddNewInterval(timeline),
                            update_interval: () => this.wsUpdateIntervalParams(timeline),
                            remove_interval: () => this.wsRemoveInterval(timeline),
                            new_interval_tags: () => this.wsAddToIntervalTags(timeline),
                        };

                        if (actions[action]) {
                            actions[action]();
                        } else {
                            console.warn(`Unhandled action: ${action}`);
                        }
                    }
                };

                this.ws.onclose = () => {
                    console.warn('WebSocket connection closed');
                    this.$store.dispatch('websocket/updateTimelinesChannelStatus', false);
                    this.retryWebSocketConnection();
                };

                this.ws.onerror = error => {
                    this.$store.dispatch('websocket/updateTimelinesChannelStatus', false);
                    this.retryWebSocketConnection();
                };
            },
            retryWebSocketConnection() {
                if (retries < maxRetries) {
                    retries += 1;
                    setTimeout(() => {
                        this.connectWebSocket();
                    }, reconnectInterval);
                } else {
                    if (process.env.NODE_ENV === 'development') {
                        console.warn('The maximum number of reconnections has been reached.');
                    }
                }
            },

            async handleReconectWS(status) {
                if (status) {
                    retries = 0;
                    this.retryWebSocketConnection();
                }
            },

            wsAddNewInterval(interval) {
                const exists = this.timelines.some(timeline => timeline.id === interval.data.id);
                if (!exists) {
                    this.timelines.push(interval.data);
                    this.timelineItems = this.transformedData;
                }
            },

            wsUpdateIntervalParams(interval) {
                this.timelines = this.timelines.filter(
                    timeInterval => timeInterval.attributes.id != interval.data.attributes.id,
                );
                this.timelines.push(interval.data);
                this.timelineItems = this.transformedData;
            },

            wsRemoveInterval(timeline) {
                this.timelines = this.timelines.filter(timeInterval => timeInterval.attributes.id != timeline.id);
                this.timelineItems = this.transformedData;
            },

            wsAddToIntervalTags(timeline) {
                const currentInterval = this.timelines.find(timeInterval => timeInterval.attributes.id == timeline.id);

                if (currentInterval) {
                    currentInterval.attributes.tags = timeline.tags.data;
                    this.timelineItems = this.transformedData;
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .at-container::v-deep {
        .modal-screenshot {
            a {
                max-height: inherit;

                img {
                    max-height: inherit;
                    object-fit: fill;
                }
            }
        }
    }
    .at-container {
        position: relative;
        padding: 1em;

        &:not(:last-child) {
            padding-right: 1.5em;
        }
    }

    .sidebar {
        padding: 30px 0;
    }

    .timeline {
        &__loader {
            z-index: 0;
            border-radius: 20px;
        }
    }

    .timeline-type {
        margin-left: 10px;
        border-radius: 5px;

        .at-btn:first-child {
            border-radius: 5px 0 0 5px;
        }

        .at-btn:last-child {
            border-radius: 0 5px 5px 0;
        }

        &-btn {
            border: 1px solid #eeeef5;
            color: #b1b1be;
            font-size: 15px;
            font-weight: 500;
            height: 40px;

            &.active {
                color: #ffffff;
                background: #2e2ef9;
            }
        }
    }

    .controls-row {
        z-index: 1;
        position: relative;

        &__btn {
            .theme-dark & {
                &:hover {
                    color: #c4c4cf;
                    background-color: #282828;
                }
            }
        }
    }

    .graph {
        width: 100%;
    }

    .pr-1 {
        padding-right: 1em;
    }

    .timeline {
        position: relative;
    }

    .loading-spinner {
        position: fixed;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1000;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .spinner {
        width: 40px;
        height: 40px;
        border: 4px solid rgba(0, 0, 0, 0.2);
        border-top-color: #3498db;
        border-radius: 50%;
        animation: spin 1s linear infinite;

        .theme-dark & {
            border-top-color: #ffa500;
        }
    }

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
</style>
