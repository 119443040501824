var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tags-container"},[(_vm.isMobile)?[_c('portal',{attrs:{"to":"modals"}},[(_vm.showTagsModal)?_c('tags-modal',{attrs:{"tags":_vm.filteredTags,"selected-tags":_vm.selectedTags},on:{"update:selectedTags":_vm.updateSelectedTags},model:{value:(_vm.showTagsModal),callback:function ($$v) {_vm.showTagsModal=$$v},expression:"showTagsModal"}}):_vm._e()],1),_c('div',{staticClass:"tag-container",on:{"click":function($event){_vm.showTagsModal = true}}},[_c('div',{staticClass:"tag-container"},[_vm._l((_vm.selectedTags),function(tagName,index){return _c('span',{key:'selected-' + index,staticClass:"tag",style:({ width: _vm.selectedTags.length === 1 ? '20px' : '8px' })},[_c('span',{staticClass:"tag-color-indicator"},[_c('feather-tag',{staticClass:"icon icon-tag",staticStyle:{"width":"15px"},attrs:{"stroke":_vm.getTagColor(tagName),"fill":_vm.getTagColor(tagName),"color":_vm.getTagColor(tagName)}})],1)])}),_c('span',{class:{
                        'tag-icon': true,
                        'tag-icon-active': _vm.selectedTags.length,
                        'tag-icon-active-element': _vm.selectedTags.length > 0,
                    }},[_c('i',{staticClass:"icon icon-tag"})])],2)])]:[_c('at-dropdown',{attrs:{"placement":_vm.position,"trigger":_vm.trigger},on:{"on-dropdown-command":_vm.onDropdownCommand}},[_c('at-tooltip',{attrs:{"content":_vm.tooltipContent}},[_c('div',{staticClass:"tag-container"},[_vm._l((_vm.selectedTags),function(tagName,index){return _c('span',{key:'selected-' + index,staticClass:"tag",style:({ width: _vm.selectedTags.length === 1 ? '13px' : 'px' })},[_c('feather-tag',{staticClass:"icon icon-tag",staticStyle:{"width":"20px"},attrs:{"stroke":_vm.getTagColor(tagName),"fill":_vm.getTagColor(tagName),"color":_vm.getTagColor(tagName)}})],1)}),_c('span',{class:{
                            'tag-icon': true,
                            'tag-icon-active': _vm.selectedTags.length,
                            'tag-icon-active-element': _vm.selectedTags.length > 0,
                        }},[_c('i',{staticClass:"icon icon-tag"})])],2)]),_c('at-dropdown-menu',{attrs:{"slot":"menu"},nativeOn:{"click":function($event){$event.stopPropagation();}},slot:"menu"},[_c('div',{staticClass:"dropdown-search"},[_c('at-input',{attrs:{"placeholder":_vm.$t('dashboard.tagSearch')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"scrollable-tags-container",on:{"scroll":_vm.handleScroll}},[_c('at-checkbox-group',{on:{"input":_vm.emitSelectedTags},model:{value:(_vm.selectedTags),callback:function ($$v) {_vm.selectedTags=$$v},expression:"selectedTags"}},_vm._l((_vm.filteredTags),function(tagObj,index){return _c('at-checkbox',{key:index,attrs:{"label":tagObj.attributes.name}},[_c('span',{staticClass:"tag-color-indicator",style:({ backgroundColor: tagObj.attributes.color })}),_vm._v(" "+_vm._s(tagObj.attributes.name)+" ")])}),1)],1),(_vm.checkCreateAccess)?_c('at-button',{staticClass:"create-tag-button",attrs:{"disabled":_vm.isCreateButtonDisabled},on:{"click":_vm.createTag}},[_c('i',{staticClass:"icon icon-plus"}),_vm._v(" "+_vm._s(_vm.$t('dashboard.buttons.createTag'))+" "),(_vm.createTagButtonText)?[_vm._v("\""+_vm._s(_vm.createTagButtonText)+"\"")]:_vm._e()],2):_vm._e()],1)],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }