<template>
    <at-tooltip placement="bottom">
        <div class="additional-plans">
            <span
                :class="{ 'icon-active': isActive, 'icon-inactive': !isActive }"
                :disabled="!selectedTask"
                :style="{ cursor: selectedTask ? 'pointer' : 'not-allowed' }"
                @click="switchActive"
            >
                <img
                    class="icon-dollar-sign"
                    :src="require('../../../icon/dollar-sign.svg')"
                    :style="{ filter: iconFilter }"
                />
            </span>
        </div>
        <template #content>
            <span>{{ $t('dashboard.billableMessage') }}</span>
        </template>
    </at-tooltip>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: 'AdditionalPlans',
        props: {
            selectedBillable: {
                type: Boolean,
                default: null,
            },
            selectedTask: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                isActive: false,
                showDropdown: false,
            };
        },
        computed: {
            ...mapState(['iconFilter']),
            iconFilter() {
                return this.isActive ? 'invert(100%)' : 'none';
            },
        },
        created() {
            this.isActive = this.selectedBillable == null ? false : this.selectedBillable;
        },
        methods: {
            switchActive() {
                if (this.selectedTask) {
                    this.isActive = !this.isActive;
                    this.$emit('billable-status', this.isActive);
                }
            },
        },
        watch: {
            selectedBillable(value) {
                this.isActive = value;
            },
        },
    };
</script>
