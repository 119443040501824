<template>
    <div class="timeline">
        <div class="timeline__content-wrapper">
            <div class="timeline">
                <router-view :key="$route.fullPath" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Timeline',

        data() {
            return {
                activeSection: 'times',
                subAccessStatus: false,
            };
        },

        async beforeRouteEnter(to, from, next) {
            if (to.path === '/dashboard/timeline/' || to.path === '/dashboard/timeline') {
                next('/dashboard/timeline/times');
            } else {
                next();
            }
        },

        async beforeRouteUpdate(to, from, next) {
            if (to.path === '/dashboard/timeline/' || to.path === '/dashboard/timeline') {
                this.$router.replace('/dashboard/timeline/times').catch(err => {
                    if (err.name !== 'NavigationDuplicated') {
                        throw err;
                    }
                });
            } else {
                next();
            }
        },

        created() {
            this.checkSubAccess();
        },

        methods: {
            setActive(section) {
                this.activeSection = section;
                this.$router.push(`/dashboard/timeline/${section}`);
            },
            async checkSubAccess() {
                this.subAccessStatus = await this.$store.dispatch('company/isSubscription', 'development');
            },
            handleButtonActive(section) {
                this.activeSection = section;
            },
        },
    };
</script>

<style scoped lang="scss">
    .timeline {
        &__loader {
            z-index: 0;
            border-radius: 20px;
        }

        &__buttons {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 1rem;
        }

        .add-time-button {
            margin-left: auto;
        }

        .timeline-nav {
            float: left;
            gap: 1rem;
        }

        .timeline-menu {
            position: relative;
            z-index: 1;
        }
    }
</style>
